'use client'

import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth, useUser } from '@clerk/nextjs';
import type { User } from '@clerk/nextjs/server';
import type { UserResource } from '@clerk/types';
import { useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from 'use-analytics';

import { useRouter } from 'next/navigation';

import { useLocalStorage } from 'hooks/useLocalStorage';

export enum UserType {
  Agent = 'agent',
  Owner = 'owner',
  Tenant = 'tenant',
}

type LivUserProviderContextType = {
  user: User | UserResource | null
  setUser: Dispatch<SetStateAction<User | UserResource | null>>
  userType: UserType | undefined
  setUserType: Dispatch<SetStateAction<UserType | undefined>>
  isSignedIn: boolean
  setIsSignedIn: Dispatch<SetStateAction<boolean>>
  sessionId: string | null
  setSessionId: Dispatch<SetStateAction<string | null>>
  livUserUUID: string | null
  setLivUserUUID: Dispatch<SetStateAction<string | null>>
}

export const LivUserProviderContext = createContext<LivUserProviderContextType>(
  {
    user: null,
    setUser: () => undefined,
    userType: undefined,
    setUserType: () => undefined,
    isSignedIn: false,
    setIsSignedIn: () => undefined,
    sessionId: null,
    setSessionId: () => undefined,
    livUserUUID: null,
    setLivUserUUID: () => undefined,
  },
)

LivUserProviderContext.displayName = 'LivUserContext'
type LivUserProviderContextProviderClientProps = {
  children: React.ReactNode
  user: string // Serialized User
  isSignedIn: boolean
}

function LivUserProviderContextProviderClient(props: Readonly<LivUserProviderContextProviderClientProps>): JSX.Element {
  const { user: clerkUser, isLoaded } = useUser()
  const { sessionId: clerkSessionId, isSignedIn: clerkIsSignedIn } = useAuth()

  const [user, setUser] = useState<User | UserResource | null>(
    JSON.parse(props.user) as User | UserResource | null,
  )
  const [userType, setUserType] = useLocalStorage<UserType | undefined>(
    'liv.userPreferences.userType',
    undefined,
  )
  const [isSignedIn, setIsSignedIn] = useState<boolean>(props.isSignedIn)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [livUserUUID, setLivUserUUID] = useState<string | null>(
    typeof user?.publicMetadata.liv_clerk_user_uuid === 'string'
      ? user.publicMetadata.liv_clerk_user_uuid
      : null,
  )
  const queryClient = useQueryClient()
  const router = useRouter()

  const { identify, reset } = useAnalytics()

  useEffect(() => {
    if (!isLoaded) return
    if (clerkUser && isSignedIn) {
      setUser(clerkUser as unknown as User)
      if (clerkUser.publicMetadata.liv_clerk_user_uuid) {
        const intercomProperties = {
          phone: clerkUser.primaryPhoneNumber?.phoneNumber ?? '',
          name: `${clerkUser.firstName ?? ''} ${clerkUser.lastName ?? ''}`,
          created_at: clerkUser.createdAt
            ? Math.floor(clerkUser.createdAt.getTime() / 1000)
            : null,
          email: clerkUser.primaryEmailAddress?.emailAddress ?? '',
        }

        const userInfo = {
          ...intercomProperties,
          liv_user_uuid: clerkUser.publicMetadata.liv_clerk_user_uuid as string,
          clerk_id: clerkUser.id,
        }
        identify(
          clerkUser.publicMetadata.liv_clerk_user_uuid as string,
          userInfo,
        ).catch((error: unknown) => {
          // eslint-disable-next-line no-console -- trying to catch the error
          console.error('Failed to identify user:', error);
        });
      }
    }
  }, [clerkUser, identify, isLoaded, isSignedIn])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null
    // Clear the interval when the window is about to be unloaded
    const handleBeforeUnload = (): void => {
      if (intervalId) clearInterval(intervalId)
    }

    if (clerkIsSignedIn && livUserUUID === null) {
      if (typeof clerkUser?.publicMetadata.liv_clerk_user_uuid === 'string') {
        setLivUserUUID(clerkUser.publicMetadata.liv_clerk_user_uuid)

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- it was working fine
        if (intervalId) clearInterval(intervalId)
      } else {
        intervalId = setTimeout(() => {
          handleBeforeUnload()
          window.location.reload()
        }, 5000)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      if (intervalId) clearInterval(intervalId)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [clerkIsSignedIn, clerkUser, livUserUUID, queryClient, router])

  useEffect(() => {
    if (clerkIsSignedIn !== undefined) {
      setIsSignedIn(() => clerkIsSignedIn)
    }
    // Clear user data when when user logs out
    if (!clerkIsSignedIn && isSignedIn) {
      setLivUserUUID(() => null) // reset livUserUUID
      reset().catch((error: unknown) => {
        // eslint-disable-next-line no-console -- trying to catch the error
        console.error('Failed to reset analytics:', error);
      }) // reset analytics
      setIsSignedIn(() => false) // reset isSignedIn
    }
  }, [clerkIsSignedIn, isSignedIn, reset])

  useEffect(() => {
    if (clerkSessionId) {
      setSessionId(clerkSessionId)
    }
  }, [clerkSessionId])

  const value = useMemo(
    () => ({
      user,
      setUser,
      userType,
      setUserType,
      isSignedIn,
      setIsSignedIn,
      sessionId,
      setSessionId,
      livUserUUID,
      setLivUserUUID,
    }),
    [
      user,
      setUser,
      userType,
      setUserType,
      isSignedIn,
      setIsSignedIn,
      sessionId,
      setSessionId,
      livUserUUID,
      setLivUserUUID,
    ],
  )

  return (
    <LivUserProviderContext.Provider value={value}>
      {props.children}
    </LivUserProviderContext.Provider>
  )
}

export function useLivUserContext(): LivUserProviderContextType {
  const context = useContext(LivUserProviderContext)
  return context
}

export default LivUserProviderContextProviderClient
