'use client'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LogoText from '@UI/sharedComponents/LogoText'

import { useTranslations } from 'next-intl'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

import pages from './navigation'

const { useRouter } = getLivSharedPathnamesNavigation()

function Footer(): JSX.Element {
  const theme = useTheme()
  const t = useTranslations('Footer')
  const tMenu = useTranslations('Menu')
  const router = useRouter()

  return (
    <footer
      style={{
        backgroundColor: theme.palette.primary.contrastText,
        marginTop: 'auto',
      }}
    >
      <Divider color={theme.palette.grey[100]} />
      <Container sx={{ pt: 4 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'baseline',
              marginY: { xs: 1, sm: 0 },
            }}
          >
            <LogoText variant="h4" />
            <Link
              href="https://www.youtube.com/@liv.marketplace"
              target="_blank"
            >
              <YouTubeIcon color="secondary" />
            </Link>
            <Link href="https://www.facebook.com/my.liv.place" target="_blank">
              <FacebookIcon color="secondary" />
            </Link>
            <Link href="https://www.instagram.com/my.liv.place" target="_blank">
              <InstagramIcon color="secondary" />
            </Link>
            <Link
              href="https://www.linkedin.com/company/liv-marketplace/"
              target="_blank"
            >
              <LinkedInIcon color="secondary" />
            </Link>
          </Stack>

          <Box
            sx={{
              width: 'auto',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: 2,
              mt: 1,
              marginX: { xs: 0, sm: 2 },
              marginY: 0,
            }}
          >
            {pages.map((p) => (
              <Link
                key={p.id}
                component="a"
                href={p.option.href}
                target={p.option.target}
                color="text.primary"
                variant="subtitle2"
              >
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                  {' '}
                  {/* Ensuring text is centered */}
                  {/* @ts-expect-error titles come from navigation.ts */}
                  {tMenu(p.title)}
                </Box>
              </Link>
            ))}
          </Box>
          <Button
            variant="outlined"
            onClick={() => router.push('/user/sign-in/')}
            sx={{ minWidth: '100px', marginY: { xs: 2, sm: 0 } }}
          >
            {t('Login')}
          </Button>
        </Box>
        <Box sx={{ mt: 3, mb: 8 }}>
          <Typography
            align="center"
            variant="subtitle2"
            color="text.secondary"
            gutterBottom
            component="p"
          >
            &copy; {new Date().getFullYear()} Liv Marketplace.{' '}
            {t('AllRightsReserved')}
          </Typography>
          <Typography
            align="center"
            variant="caption"
            color="text.secondary"
            component="p"
          >
            {t('Cookies')}
          </Typography>
        </Box>
      </Container>
    </footer>
  )
}

export default Footer
